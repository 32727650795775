#domain {
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #222;
  color: #fff;
  border: none;
  border: 1px solid #444;
  font-size: 18px;
}

.authorize-button {
  position: relative;
  padding: 15px 20px;
  border-radius: 7px;
  border: 1px solid rgb(61, 106, 255);
  font-size: 18px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.authorize-button:hover {
  background: rgb(61, 106, 255);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.authorize-button:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

.authorize-button::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 50px 30px #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

.authorize-button:active {
  -webkit-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -webkit-transition: -webkit-box-shadow 0.2s ease-in;
  transition: -webkit-box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in, -webkit-box-shadow 0.2s ease-in;
}


@-webkit-keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.icon-pulse {
  -webkit-animation: pulse 2.5s 0s linear reverse infinite;
  animation: pulse 2.5s 0s linear reverse infinite;
}


.authorize-card {
  width: 400px;
  height: 550px;
  background: rgb(255, 0, 0);
  background: linear-gradient(170deg, rgba(255, 0, 0, 1) 0%, rgba(139, 0, 255, 1) 100%);
  border-radius: 22px;
  transition: all .3s;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card2 {
  width: 400px;
  height: 550px;
  background-color: #1a1a1a;
  border-radius: 20px;
  border: 2px solid #333;
  transition: all .2s;
}

.card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
  border: 0px solid #333;
}

.authorize-card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
}

.authorize-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  line-height: 1.1em;
}

.authorize-inner {
  padding: 35px;
  text-align: center;
}

#continue {
  opacity: 0;
  display: none;
  transition: 0.7s;
}

#help {
  font-size: 13px;
  color: #777;
}